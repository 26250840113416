import { useContext, useState } from "react";
import Context from "../../store/context";
import Button from "../Elements/Button";
import NKAPI from "../NKAPI";
import KiwiBird from "../../images/NK_KiwiBird_White.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";

export default function ConnectDiscord(props) {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);
    const { t } = useTranslation(); // locale

    // Get connected providers for user
    let translatedProviders = NKAPI.translateProviders(
        globalState.user.providersAvailable
    );
    // Remove discord provider if provisional flag has been set
    if (NKAPI.getQueryVariable("discordProvisional")) {
        translatedProviders = translatedProviders.filter(
            (provider) => provider !== "discord"
        );
    }
    const [connectedProviders, setConnectedProviders] =
        useState(translatedProviders);

    function closeModal() {
        props.setIsModalOpen(false);
    }

    function connectAccount() {
        props.setConnectDiscord(true);
        closeModal();
    }

    return (
        <>
            {/* LiNK Code Modal */}
            <Modal
                isOpen={props.isModalOpen}
                onRequestClose={closeModal}
                contentLabel="LiNK Code"
                className="2xl:w-6/12 md:w-9/12 sm:w-10/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
                overlayClassName="fixed h-screen w-full top-0 flex-center"
            >
                <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                    {/* Modal Header */}
                    <div className="h-20 flex justify-between items-center px-1">
                        <FontAwesomeIcon
                            className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                            icon={faTimes}
                            onClick={closeModal}
                        />
                    </div>

                    {/* Modal Content */}
                    <form className="flex-1 overflow-y-auto p-1">
                        {connectedProviders.includes("discord") ? (
                            <>
                                <div className="flex-center my-6">
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="w-10 h-10 text-green-500 mx-6"
                                    />
                                </div>
                                <h3 className="font-medium text-lg m-4 text-center">
                                    You're LiNK Account is already connected to
                                    Discord!
                                </h3>

                                <div className="flex flex-wrap justify-center mt-8">
                                    <Button
                                        text={t(
                                            "deleteaccount.markedmodalokay"
                                        )}
                                        color="gray-400"
                                        className="mr-4 mb-4"
                                        onClick={closeModal}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="flex-center my-6">
                                    <div className="flex-center w-16 h-16 rounded-lg bg-discord">
                                        <FontAwesomeIcon
                                            icon={faDiscord}
                                            className="w-8 h-8 text-white"
                                        />
                                    </div>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="w-5 h-5 text-gray-300 mx-6"
                                    />
                                    <div className="flex-center w-16 h-16 rounded-lg bg-nk">
                                        <img
                                            src={KiwiBird}
                                            className="w-9 h-9"
                                            alt="Ninja Kiwi Logo"
                                        />
                                    </div>
                                </div>
                                <h3 className="font-medium text-lg m-4 text-center">
                                    Connect your Discord account to your LiNK
                                    account?
                                </h3>

                                <div className="flex flex-wrap justify-center mt-8">
                                    <Button
                                        text={t("common.back")}
                                        color="gray-400"
                                        className="mr-4 mb-4"
                                        onClick={closeModal}
                                    />
                                    <Button
                                        // TODO: Translate
                                        text="Connect Account"
                                        className="mb-4"
                                        onClick={connectAccount}
                                    />
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </Modal>
        </>
    );
}
