import Button from "../../../components/Elements/Button";
import Input from "../../../components/Elements/Input";
import NKAPI from "../../../components/NKAPI";

import { faUserCircle } from "@fortawesome/pro-duotone-svg-icons";
import {
    faAt,
    faUserCircle as faUserCircleLight
} from "@fortawesome/pro-light-svg-icons";
import { faRandom } from "@fortawesome/pro-duotone-svg-icons";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Context from "../../../store/context";
import { useTranslation } from "react-i18next";

function SignUp_Child() {
    // Global State
    const { globalDispatch, globalState } = useContext(Context);

    const { t } = useTranslation();

    const [createAnonLoading, setCreateAnonLoading] = useState(false);
    const [finishAnonLoading, setFinishAnon] = useState(false);
    const [newAccData, setNewAccData] = useState({});
    const [isCreated, setIsCreated] = useState(false);
    const [displayName, setDisplayName] = useState("");
    const [recoveryCode, setRecoveryCode] = useState("xxxxxxxxx-xxxx");
    const [namesList, setNamesList] = useState({
        first: ["Bloon"],
        second: ["Tower"]
    }); // Fallback names

    const navigate = useNavigate();
    const location = useLocation();

    function createAnon() {
        setCreateAnonLoading(true);
        NKAPI.request("/user/create/anonymous", { age: globalState.age })
            .then((data) => {
                let json = JSON.parse(data?.data?.data);
                let anonAccount = json?.user;
                let sessionData = json?.session;

                setNewAccData(data.data); // store reference to initial acc creation for when user finishes creation process

                // Show name generator and recovery code
                setIsCreated(true);

                // Update recovery code
                setRecoveryCode(`${json.user.shortcode}-${json.recoverCode}`);
                setDisplayName(anonAccount.displayName);

                // Get names list
                NKAPI.getNamesList().then((data) => {
                    setNamesList(data.data);
                    // console.log(data.data);
                });

                // Disable exit buttons
                globalDispatch({
                    type: "ui/navigation",
                    payload: {
                        isShown: false
                    }
                });
                // Update user state
                globalDispatch({
                    type: "user/login",
                    payload: {
                        user: anonAccount,
                        session: sessionData
                    }
                });

                NKAPI.sessionID = sessionData.sessionID;

                setCreateAnonLoading(false);
            })
            .catch((error) => {
                console.error(error);
                error = error.toJSON();
                if (error?.message === "Network Error") {
                    // No internet connection, show lost connection screen
                    globalDispatch({
                        type: "modal/offline",
                        payload: {
                            isShown: true
                        }
                    });
                }
            });
    }

    function generateName() {
        let nameInput = document.getElementById("signUp_DisplayName");

        // Generate name
        let firstName =
            namesList.first[Math.floor(Math.random() * namesList.first.length)];
        let secondName =
            namesList.second[
                Math.floor(Math.random() * namesList.second.length)
            ];
        let name = firstName + secondName;
        // Add random digits until names is 14 characters - Max of 4 digits
        let addedNumbers = 0;
        while (name.length < 14 && addedNumbers++ < 4) {
            name += Math.floor(9 * Math.random());
        }

        // Update name input
        nameInput.value = name;
    }

    function sendOptionalEmail() {
        // Start spinner
        setFinishAnon(true);
        // Do optional recovery email
        let optionalEmail = document
            .getElementById("signUp_OptionalEmail")
            .value.trim();
        if (optionalEmail) {
            NKAPI.request("/user/anon/recovery/send", {
                email: optionalEmail,
                app: NKAPI.appID
            })
                .then(() => {
                    finishAnonCreation();
                })
                .catch((e) => {
                    // NKAPI.nkNoti(JSON.stringify(e));
                    globalDispatch({
                        type: "modal/error",
                        payload: {
                            isShown: true,
                            errorMsg: JSON.stringify(e)
                        }
                    });
                    finishAnonCreation();
                });
        } else {
            finishAnonCreation();
        }
    }

    function finishAnonCreation() {
        let newName = document.getElementById("signUp_DisplayName").value;

        // Reenable exit buttons
        globalDispatch({
            type: "ui/navigation",
            payload: {
                isShown: true
            }
        });

        if (newName && newName !== globalState.user.displayName) {
            // Update user state
            let newUser = globalState.user;
            newUser.displayName = newName;
            // Update username
            NKAPI.request("/user/update", newUser).then(() => {
                NKAPI.request("/user/current", newUser).then((data) => {
                    // End spinner
                    setFinishAnon(false);

                    if (NKAPI.debug || NKAPI.gotoProfile()) {
                        navigate("/profile" + location.hash);
                        NKAPI.sendToSDK("//login", data.data);
                    } else {
                        NKAPI.sendToSDK("//login", data.data);
                    }
                });
            });
        } else {
            // Login without updating displayname
            if (NKAPI.debug || NKAPI.gotoProfile()) {
                navigate("/profile" + location.hash);
                NKAPI.sendToSDK("//login", newAccData);
            } else {
                NKAPI.sendToSDK("//login", newAccData);
            }
        }
    }

    return (
        <div>
            {isCreated ? (
                <div>
                    {/* Recovery Code */}
                    <div className="w-full border-blue-500 bg-blue-600 text-gray-100 border-2 rounded-md p-4">
                        <h1 className="text-xl font-semibold">
                            {t("common.important")}
                        </h1>
                        <div className="text-lg flex flex-col space-y-3">
                            <p>{t("onboarding.recovery_intro")}</p>

                            <p className="text-3xl font-medium">
                                {recoveryCode}
                            </p>

                            <p>{t("onboarding.recovery_outro")}</p>
                        </div>
                    </div>

                    <div className="h-0.5 w-full bg-gray-600 my-8"></div>

                    {/* Optional Email */}
                    <p className="font-medium text-lg text-white">
                        {t("onboarding.optional_recovery_email_intro")}
                    </p>
                    <Input
                        placeholder={t("contact.label_email")}
                        icon={faAt}
                        id="signUp_OptionalEmail"
                        className="my-2"
                    />
                    <p className="text-sm text-white">
                        {t("onboarding.optional_recovery_email_details")}
                    </p>

                    <div className="h-0.5 w-full bg-gray-600 my-8"></div>

                    {/* Name Randomiser */}
                    <div className="flex flex-col space-y-4">
                        <p className="font-medium text-lg text-white">
                            {t("onboarding.signup_choosename")}
                        </p>
                        <Input
                            placeholder={t("onboarding.signup_displayname")}
                            icon={faUserCircleLight}
                            id="signUp_DisplayName"
                            readOnly={true}
                            value={displayName}
                        />
                        <Button
                            className="bg-blue-500 focus:ring-blue-500 max-w-xs w-full"
                            text={t("onboarding.signup_randomizename")}
                            icon={faRandom}
                            onClick={generateName}
                        />
                        <Button
                            className="bg-nk focus:ring-nk w-48"
                            text={t("onboarding.signup_finish")}
                            onClick={sendOptionalEmail}
                            spinner={finishAnonLoading}
                            disabled={finishAnonLoading}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <div className="font-medium text-sm lg:text-xl space-y-3 text-white">
                        <p>{t("onboarding.child_intro")}</p>
                        <p>{t("onboarding.child_introagree")}</p>
                    </div>
                    <div className="mt-4 space-y-4">
                        <Button
                            className="w-full max-w-sm"
                            color="green-400"
                            text={t("onboarding.child_agree")}
                            icon={faUserCircle}
                            onClick={createAnon}
                            spinner={createAnonLoading}
                        />
                        <Button
                            color="gray-400"
                            text={t("common.back")}
                            onClick={() => {
                                globalDispatch({
                                    type: "onboarding/age",
                                    payload: {
                                        age: 0
                                    }
                                });
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default SignUp_Child;
