// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockAlt } from "@fortawesome/pro-light-svg-icons";
import {
    // faFacebook,
    faGooglePlay,
    faSteam
} from "@fortawesome/free-brands-svg-icons";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import { faAt } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

import Button from "../../../components/Elements/Button";
import Input from "../../../components/Elements/Input";
import { useState, useContext, useEffect } from "react";
import Context from "../../../store/context";
import NKAPI from "../../../components/NKAPI";
import { useLocation, useNavigate } from "react-router-dom";
import { faPhoneLaptop } from "@fortawesome/pro-regular-svg-icons";
import GameCenter from "../../../images/game-center-128x128.png";
import { useTranslation } from "react-i18next";

export default function SignUp_Full() {
    // Global State
    const { globalDispatch } = useContext(Context);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    // Form states
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState("");
    const [passError, setPassError] = useState(false);
    const [passErrorMsg, setPassErrorMsg] = useState("");
    const [passConfError, setPassConfError] = useState(false);
    const [legalError, setLegalError] = useState(false);
    const [signUpError, setSignUpError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isChecked, setIsChecked] = useState(false);
    let [isNewsletterChecked, setIsNewsletterChecked] = useState(false);
    let [isNKNewsChecked, setIsNKNewsChecked] = useState(false);

    // Remove discord from providers so we have an accurate array of "available" providers
    if (NKAPI.availableProviders.includes("discord")) {
        NKAPI.availableProviders.splice(
            NKAPI.availableProviders.indexOf("discord"),
            1
        );
    }

    useEffect(() => {
        // alert("DEBUG: Full Sign Up page mounted");

        globalDispatch({
            type: "layout/update",
            payload: { layoutFull: true }
        });

        // Default error message
        let msg = t("error.valid_email");
        setEmailErrorMsg(msg);
        msg = t("error.pass_req");
        setPassErrorMsg(msg);

        // eslint-disable-next-line
    }, []);

    function signUpSubmit(e) {
        e.preventDefault();

        // local bools, as 'setEmailError' states only update after a new render, so can't be used for checks in this function
        let m_emailError = false;
        let m_passError = false;
        let m_passConfError = false;
        let m_legalError = false;

        // Get form data
        let email = document.getElementById("signUp_email").value.trim();
        let pass = document.getElementById("signUp_password").value;
        let passConf = document.getElementById(
            "signUp_passwordConfirmation"
        ).value;
        let legal = isChecked;

        // Verify Email
        if (email && NKAPI.validateEmail(email)) {
            setEmailError(false);
            email = email.trim();
            m_emailError = false;
        } else {
            setEmailError(true);
            m_emailError = true;
            setEmailErrorMsg(t("error.valid_email"));
        }

        // Verify Pass
        if (pass) {
            // Check length
            if (pass.length < 6) {
                let msg = t("error.pass_tooshort");
                setPassErrorMsg(msg);
                setPassError(true);
                m_passError = true;
            } else {
                // Check pass confirmation
                if (passConf && passConf === pass) {
                    setPassConfError(false);
                    m_passConfError = false;
                    setPassError(false);
                    m_passError = false;
                } else {
                    setPassConfError(true);
                    m_passConfError = true;
                }
            }
        } else {
            setPassError(true);
            m_passError = true;
            let msg = t("error.pass_req");
            setPassErrorMsg(msg);
        }

        // Verify legal
        if (legal) {
            setLegalError(false);
            m_legalError = false;
        } else {
            setLegalError(true);
            m_legalError = true;
        }

        let marketing = {
            NEWS: isNewsletterChecked ? 1 : 0,
            MARKETING: isNKNewsChecked ? 1 : 0
        };

        if (
            !m_emailError &&
            !m_passError &&
            !m_passConfError &&
            !m_legalError
        ) {
            // Submit sign up request
            setIsLoading(true);
            setSignUpError(false);
            NKAPI.request("/user/create/kloud", {
                email: email,
                password: pass,
                username: "N/A",
                marketing
            })
                .then(() => {
                    // Login to new account, then move to profile
                    NKAPI.request("/user/login/kloud", {
                        email: email,
                        password: pass
                    })
                        .then((data) => {
                            let userData = JSON.parse(data.data.data);

                            // Update user state
                            globalDispatch({
                                type: "user/login",
                                payload: {
                                    user: userData.user,
                                    session: userData.session
                                }
                            });

                            setIsLoading(false);

                            // Login
                            if (NKAPI.debug || NKAPI.gotoProfile()) {
                                navigate("/profile" + location.hash);
                                NKAPI.sendToSDK("//login", data.data);
                            } else {
                                NKAPI.sendToSDK("//login", data.data);
                            }
                        })
                        .catch(() => {
                            setIsLoading(false);
                            // console.log(error);
                            setSignUpError(true);
                        });
                })
                .catch((error) => {
                    setIsLoading(false);

                    // Check no connection
                    if (error?.toJSON()?.message === "Network Error") {
                        // No internet connection, show lost connection screen
                        globalDispatch({
                            type: "modal/offline",
                            payload: {
                                isShown: true
                            }
                        });
                        return;
                    }

                    // Catch error responses
                    if (
                        error?.response?.data?.error?.type ===
                        "ERR_ACCOUNT_KLOUD_EMAIL_ALREADY_TAKEN"
                    ) {
                        setEmailError(true);
                        setEmailErrorMsg(t("error.valid_emailexists"));
                    }
                });
        }
    }

    const [providerLoading, setProviderLoading] = useState("");

    let providerInterval;
    let intervalTimeout = 15;
    let intervalCount = 0;

    function signUpProviderSDK(provider) {
        setProviderLoading(provider);

        // Listen for updates
        intervalCount = 0;
        providerInterval = setInterval(() => {
            signUpProviderUpdate(null, null, true);
        }, 2000);

        // Sign up using SDK
        NKAPI.sendToSDK(`//login?provider=${provider}`);
        navigate("/profile" + location.hash);
    }
    function signUpProvider(provider) {
        setProviderLoading(provider);

        NKAPI.request(`/user/${provider}/start`, {})
            .then((d) => {
                let loginData = JSON.parse(d.data.data);

                // Open OAuth window
                if (NKAPI.debug)
                    window.open(loginData.facebookStartRedirectURL);
                else window.location = loginData.facebookStartRedirectURL;

                // Listen for updates
                intervalCount = 0;
                providerInterval = setInterval(() => {
                    signUpProviderUpdate(provider, loginData);
                }, 2000);
            })
            .catch((e) => {
                setProviderLoading("");

                if (e?.toJSON()?.message === "Network Error") {
                    // No internet connection, show lost connection screen
                    globalDispatch({
                        type: "modal/offline",
                        payload: {
                            isShown: true
                        }
                    });
                }
            });
    }
    function signUpProviderUpdate(provider, loginData, sdk) {
        intervalCount++;
        // Check if provider has errored
        if (NKAPI.hasProviderErrored || intervalCount >= intervalTimeout) {
            NKAPI.hasProviderErrored = false;
            // Cancel spinner
            setProviderLoading("");
            clearInterval(providerInterval);

            // Show error
            globalDispatch({
                type: "modal/error",
                payload: {
                    isShown: true,
                    errorMsg: "error.login_timedout"
                }
            });

            return;
        }

        if (!sdk) {
            NKAPI.request(`/user/${provider}/update`, {
                linkToken: loginData.linkToken
            })
                .then((d) => {
                    let loginData = JSON.parse(d.data.data);

                    if (loginData.facebookToken !== "NONE") {
                        clearInterval(providerInterval);

                        signUpProviderComplete(provider, loginData);
                    }
                })
                .catch(() => {
                    setProviderLoading("");
                    // Show error feedback to user
                    globalDispatch({
                        type: "modal/error",
                        payload: {
                            isShown: true
                        }
                    });
                });
        }
    }
    function signUpProviderComplete(provider, loginData) {
        setProviderLoading("");

        // Do sign up request
        NKAPI.request(`/user/create/${provider}`, {
            accessToken: loginData.facebookToken,
            displayName: "Player"
        })
            .then((d) => {
                // Do login request
                NKAPI.request(`/user/login/${provider}`, {
                    accessToken: loginData.facebookToken
                })
                    .then((d) => {
                        // Update user state
                        globalDispatch({
                            type: "user/login",
                            payload: {
                                user: JSON.parse(d.data.data).user,
                                session: JSON.parse(d.data.data).session
                            }
                        });

                        if (NKAPI.debug || NKAPI.gotoProfile()) {
                            navigate("/profile" + location.hash);
                            NKAPI.sendToSDK("//login", d.data);
                        } else {
                            NKAPI.sendToSDK("//login", d.data);
                        }
                    })
                    .catch(() => {
                        setProviderLoading("");
                        // Show error
                        globalDispatch({
                            type: "modal/error",
                            payload: {
                                isShown: true
                            }
                        });
                    });
            })
            .catch((e) => {
                setProviderLoading("");

                let errorMsg = "";
                if (
                    e?.response?.data?.error?.type ===
                    "ERR_ACCOUNT_FACEBOOK_ALREADY_USED"
                ) {
                    errorMsg =
                        "This provider has already been connected to another LiNK Account. Please contact Ninja Kiwi Support at support@ninjakiwi.com";
                }

                // Show error
                globalDispatch({
                    type: "modal/error",
                    payload: {
                        isShown: true,
                        errorMsg: errorMsg
                    }
                });
            });
    }

    return (
        <div className="w-full flex flex-col md:flex-row">
            {/* Sign Up Form */}
            <form className="space-y-4 flex-1" onSubmit={signUpSubmit}>
                {/* Email */}
                <Input
                    type="email"
                    placeholder={t("common.email")}
                    name="email"
                    id="signUp_email"
                    icon={faAt}
                    state={emailError}
                    errorText={emailErrorMsg}
                    onChange={() => {
                        setEmailError(false);
                    }}
                />

                {/* Password */}
                <Input
                    type="password"
                    placeholder={t("common.pass")}
                    name="password"
                    id="signUp_password"
                    icon={faLockAlt}
                    state={passError}
                    errorText={passErrorMsg}
                    autocomplete="new-password"
                    onChange={() => {
                        setPassError(false);
                    }}
                />
                <Input
                    type="password"
                    placeholder={t("common.pass_confirm")}
                    name="signUp_passwordConfirmation"
                    id="signUp_passwordConfirmation"
                    icon={faLockAlt}
                    state={passConfError}
                    errorText={t("error.pass_nomatch")}
                    autocomplete="new-password"
                    onChange={() => {
                        setPassConfError(false);
                    }}
                />
                {/* TODO: Add pass strength meter? */}

                {/* Terms Agreement*/}
                <div className="flex items-center flex-wrap">
                    <div
                        className={`h-12 w-12 lg:h-14 lg:w-14 rounded-full flex-center flex-shrink-0  cursor-pointer ${
                            isChecked
                                ? "bg-nk hover:bg-nk-600 ring-2 ring-nk ring-offset-gray-800 ring-offset-2"
                                : "bg-gray-600 hover:bg-gray-400"
                        }`}
                        id="signUp_legal"
                        onClick={() => {
                            setIsChecked(!isChecked);
                            setLegalError(false);
                        }}
                    >
                        <FontAwesomeIcon
                            className={`pointer-events-none text-2xl text-white ${
                                isChecked ? "visible" : "hidden"
                            }`}
                            icon={faCheck}
                        />
                    </div>
                    <p className="text-gray-200 flex-1 text-sm lg:text-lg pl-2 cursor-pointer leading-5 lg:leading-4 mr-2">
                        <span
                            onClick={() => {
                                setIsChecked(!isChecked);
                                setLegalError(false);
                            }}
                        >
                            {t("common.agree")}{" "}
                        </span>
                        <button
                            className="text-nk text-sm lg:text-lg bg-gray-800 font-medium focus:outline-none text-left"
                            type="button"
                            onClick={() => {
                                globalDispatch({
                                    type: "modal/terms",
                                    payload: {
                                        isShown: true
                                    }
                                });
                            }}
                        >
                            {t("common.terms")}
                        </button>
                    </p>
                    {/* Error */}
                    {legalError && (
                        <div className="bg-nk bg-opacity-80 rounded-full text-white font-semibold text-xs lg:text-sm px-4 py-1 my-2">
                            {t("error.legal_agree")}
                        </div>
                    )}
                </div>

                {/* Marketing TODO: Translate */}
                <div className="flex flex-col">
                    <h1 className="mb-2 lg:text-lg font-bold">Newsletter</h1>

                    <p className="text-sm lg:text-base mb-4">
                        By subscribing to our newsletter, we'll keep you in the
                        loop on all the latest releases, updates, and game
                        launches!
                    </p>

                    <div className="flex items-center flex-wrap">
                        <div
                            className={`h-12 w-12 lg:h-14 lg:w-14 rounded-full flex-center flex-shrink-0  cursor-pointer ${
                                isNewsletterChecked
                                    ? "bg-nk hover:bg-nk-600 ring-2 ring-nk ring-offset-gray-800 ring-offset-2"
                                    : "bg-gray-600 hover:bg-gray-400"
                            }`}
                            id="signUp_legal"
                            onClick={() => {
                                setIsNewsletterChecked(!isNewsletterChecked);
                            }}
                        >
                            <FontAwesomeIcon
                                className={`pointer-events-none text-2xl text-white ${
                                    isNewsletterChecked ? "visible" : "hidden"
                                }`}
                                icon={faCheck}
                            />
                        </div>
                        <p className="text-gray-200 flex-1 text-sm lg:text-lg pl-2 cursor-pointer leading-5 lg:leading-4 mr-2">
                            <span
                                onClick={() => {
                                    setIsNewsletterChecked(
                                        !isNewsletterChecked
                                    );
                                }}
                            >
                                {/* TODO: translate  */}
                                Game Update Newsletter
                            </span>
                        </p>
                    </div>
                    <div className="flex items-center flex-wrap mt-4">
                        <div
                            className={`h-12 w-12 lg:h-14 lg:w-14 rounded-full flex-center flex-shrink-0  cursor-pointer ${
                                isNKNewsChecked
                                    ? "bg-nk hover:bg-nk-600 ring-2 ring-nk ring-offset-gray-800 ring-offset-2"
                                    : "bg-gray-600 hover:bg-gray-400"
                            }`}
                            id="signUp_legal"
                            onClick={() => {
                                setIsNKNewsChecked(!isNKNewsChecked);
                            }}
                        >
                            <FontAwesomeIcon
                                className={`pointer-events-none text-2xl text-white ${
                                    isNKNewsChecked ? "visible" : "hidden"
                                }`}
                                icon={faCheck}
                            />
                        </div>
                        <p className="text-gray-200 flex-1 text-sm lg:text-lg pl-2 cursor-pointer leading-5 lg:leading-4 mr-2">
                            <span
                                onClick={() => {
                                    setIsNKNewsChecked(!isNKNewsChecked);
                                }}
                            >
                                {/* TODO: translate  */}
                                Ninja Kiwi Related News
                            </span>
                        </p>
                    </div>
                </div>

                {/* Login Submit */}
                <div className="flex items-center flex-wrap">
                    <Button
                        color="nk"
                        type="submit"
                        text={t("onboarding.signup")}
                        className="w-52 mr-1"
                        spinner={isLoading}
                    />
                    {signUpError && (
                        <div className="bg-nk bg-opacity-80 rounded-full text-white font-semibold text-xs lg:text-sm px-4 py-1">
                            {t("error.error_tryagain")}
                        </div>
                    )}
                </div>
                <Button
                    color="gray-400"
                    text={t("common.back")}
                    onClick={() => {
                        globalDispatch({
                            type: "onboarding/age",
                            payload: {
                                age: 0
                            }
                        });
                    }}
                />
            </form>

            {/* Divider */}
            <div className="flex items-center my-4 md:my-0">
                <div className="bg-gray-600 flex-1 h-1 rounded md:hidden"></div>
                <div className="text-gray-500 text-xl uppercase tracking-widest font-semibold px-6 lg:px-12">
                    {t("common.or")}
                </div>
                <div className="bg-gray-600 flex-1 h-1 rounded md:hidden"></div>
            </div>

            {NKAPI.availableProviders.length === 0 ? (
                <div className="flex-1 h-20 flex-center border-blue-500 bg-blue-600 text-gray-100 border-2 rounded-md p-4">
                    <div className="text-base">
                        <p className="text-center">
                            {t("common.feature_disabled")}
                        </p>
                    </div>
                </div>
            ) : (
                // {/* Third-party Providers */}
                <div className="flex flex-col space-y-4 flex-1">
                    {NKAPI.availableProviders.includes("steam") && (
                        <Button
                            text={t("onboarding.continue_with", {
                                provider: "Steam"
                            })}
                            className="bg-steam focus:ring-steam  hover:!bg-[#283144]"
                            icon={faSteam}
                            onClick={() => {
                                signUpProviderSDK("steam");
                            }}
                            spinner={providerLoading === "steam" ? true : false}
                        />
                    )}
                    {/* Facebook sign ups have been disabled for now */}
                    {/* {NKAPI.availableProviders.includes("facebook") && (
                        <Button
                            text={t("onboarding.continue_with", {
                                provider: "Facebook",
                            })}
                            className="bg-facebook focus:ring-facebook"
                            icon={faFacebook}
                            onClick={() => {
                                if (NKAPI.platform === "steam")
                                    signUpProvider("facebook");
                                else signUpProviderSDK("facebook");
                            }}
                            crossplatform={true}
                            spinner={
                                providerLoading === "facebook" ? true : false
                            }
                        />
                    )} */}
                    {NKAPI.availableProviders.includes("twitch") && (
                        <Button
                            text={t("onboarding.continue_with", {
                                provider: "Twitch"
                            })}
                            className="bg-twitch focus:ring-twitch"
                            icon={faTwitch}
                            onClick={() => {
                                signUpProvider("twitch");
                            }}
                            crossplatform={true}
                            spinner={
                                providerLoading === "twitch" ? true : false
                            }
                        />
                    )}
                    {NKAPI.availableProviders.includes("apple") && (
                        <Button
                            text={t("onboarding.continue_with", {
                                provider: "Apple"
                            })}
                            className="bg-apple focus:ring-apple"
                            icon={faApple}
                            onClick={() => {
                                signUpProviderSDK("apple");
                            }}
                            spinner={providerLoading === "apple" ? true : false}
                        />
                    )}
                    {NKAPI.availableProviders.includes("gamecenter") && (
                        <Button
                            text={t("onboarding.continue_with", {
                                provider: "Game Center"
                            })}
                            className="bg-gamecenter focus:ring-gamecenter"
                            image={GameCenter}
                            onClick={() => {
                                signUpProviderSDK("gamecenter");
                            }}
                            spinner={
                                providerLoading === "gamecenter" ? true : false
                            }
                        />
                    )}
                    {NKAPI.availableProviders.includes("googleplay") && (
                        <Button
                            text={t("onboarding.continue_with", {
                                provider: "Google Play"
                            })}
                            className="bg-googleplay focus:ring-googleplay"
                            icon={faGooglePlay}
                            onClick={() => {
                                signUpProviderSDK("googleplay");
                            }}
                            spinner={
                                providerLoading === "googleplay" ? true : false
                            }
                        />
                    )}
                    <div className="flex-center space-x-2 text-gray-500">
                        <FontAwesomeIcon
                            className="text-xl"
                            icon={faPhoneLaptop}
                        />
                        <p>- {t("common.supports_crossplatform")}</p>
                    </div>
                </div>
            )}
        </div>
    );
}
