import Button from "../Elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import NKAPI from "../NKAPI";
import { useContext, useEffect, useState } from "react";
import { faCheck, faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import Context from "../../store/context";

export default function MarketingModal(props) {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);
    const { t } = useTranslation(); // locale
    let [isLoading, setIsLoading] = useState(false);
    let [isPrefsLoading, setIsPrefsLoading] = useState(true);
    let [isNewsletterChecked, setIsNewsletterChecked] = useState(false);
    let [isNKNewsChecked, setIsNKNewsChecked] = useState(false);

    function closeModal() {
        setIsLoading(false);
        props.setIsModalOpen(false);
    }

    function savePrefs() {
        setIsLoading(true);

        let marketing = {
            NEWS: 0,
            MARKETING: 0
        };

        marketing.NEWS = isNewsletterChecked ? 1 : 0;
        marketing.MARKETING = isNKNewsChecked ? 1 : 0;

        NKAPI.request("/user/marketing", { marketing })
            .then((res) => {
                setIsLoading(false);
                let marketingData = JSON.parse(res.data.data).marketing;
                // Update user state
                let newUser = globalState.user;
                newUser.marketing = marketingData;
                globalDispatch({
                    type: "user/update",
                    payload: {
                        user: newUser
                    }
                });
                closeModal();
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    // On Mount
    useEffect(() => {
        if (!globalState?.user?.marketing) {
            // Get marketing preferences
            NKAPI.request("/user/marketing", {})
                .then((res) => {
                    let marketingData = JSON.parse(res.data.data).marketing;
                    // Update user state
                    let newUser = globalState.user;
                    newUser.marketing = marketingData;
                    globalDispatch({
                        type: "user/update",
                        payload: {
                            user: newUser
                        }
                    });

                    setIsNewsletterChecked(marketingData.NEWS);
                    setIsNKNewsChecked(marketingData.MARKETING);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setIsPrefsLoading(false);
                });
        } else {
            setIsNewsletterChecked(globalState.user.marketing?.NEWS);
            setIsNKNewsChecked(globalState.user.marketing?.MARKETING);
            setIsPrefsLoading(false);
        }
    }, []);

    return (
        <Modal
            isOpen={props.isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Account Marked For Deletion"
            className="md:w-5/12 sm:w-10/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
        >
            <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-20 flex justify-between items-center px-1">
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        {/* TODO: Translate */}
                        Newsletter
                    </h2>
                    <FontAwesomeIcon
                        className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                        icon={faTimes}
                        onClick={closeModal}
                    />
                </div>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto p-1">
                    <p className="text-base lg:text-lg mb-4">
                        By subscribing to our newsletter, we'll keep you in the
                        loop on all the latest releases, updates, and game
                        launches!
                    </p>

                    {isPrefsLoading ? (
                        <div className="flex w-full justify-center items-center h-24">
                            <FontAwesomeIcon
                                icon={faSpinnerThird}
                                className="w-6 h-6 animate-spin text-gray-300"
                            />
                        </div>
                    ) : (
                        <div className="flex flex-col">
                            <div className="flex items-center flex-wrap">
                                <div
                                    className={`h-12 w-12 lg:h-14 lg:w-14 rounded-full flex-center flex-shrink-0  cursor-pointer ${
                                        isNewsletterChecked
                                            ? "bg-nk hover:bg-nk-600 ring-2 ring-nk ring-offset-gray-800 ring-offset-2"
                                            : "bg-gray-600 hover:bg-gray-400"
                                    }`}
                                    id="signUp_legal"
                                    onClick={() => {
                                        setIsNewsletterChecked(
                                            !isNewsletterChecked
                                        );
                                    }}
                                >
                                    <FontAwesomeIcon
                                        className={`pointer-events-none text-2xl text-white ${
                                            isNewsletterChecked
                                                ? "visible"
                                                : "hidden"
                                        }`}
                                        icon={faCheck}
                                    />
                                </div>
                                <p className="text-gray-200 flex-1 lg:text-lg pl-2 cursor-pointer leading-5 lg:leading-4 mr-2">
                                    <span
                                        onClick={() => {
                                            setIsNewsletterChecked(
                                                !isNewsletterChecked
                                            );
                                        }}
                                    >
                                        {/* TODO: translate  */}
                                        Game Update Newsletter
                                    </span>
                                </p>
                            </div>
                            <div className="flex items-center flex-wrap mt-4">
                                <div
                                    className={`h-12 w-12 lg:h-14 lg:w-14 rounded-full flex-center flex-shrink-0  cursor-pointer ${
                                        isNKNewsChecked
                                            ? "bg-nk hover:bg-nk-600 ring-2 ring-nk ring-offset-gray-800 ring-offset-2"
                                            : "bg-gray-600 hover:bg-gray-400"
                                    }`}
                                    id="signUp_legal"
                                    onClick={() => {
                                        setIsNKNewsChecked(!isNKNewsChecked);
                                    }}
                                >
                                    <FontAwesomeIcon
                                        className={`pointer-events-none text-2xl text-white ${
                                            isNKNewsChecked
                                                ? "visible"
                                                : "hidden"
                                        }`}
                                        icon={faCheck}
                                    />
                                </div>
                                <p className="text-gray-200 flex-1 lg:text-lg pl-2 cursor-pointer leading-5 lg:leading-4 mr-2">
                                    <span
                                        onClick={() => {
                                            setIsNKNewsChecked(
                                                !isNKNewsChecked
                                            );
                                        }}
                                    >
                                        {/* TODO: translate  */}
                                        Ninja Kiwi Related News
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-wrap mt-6">
                        <Button
                            text="Back"
                            // TODO: Translate
                            color="gray-400"
                            className="mr-4 mb-4"
                            onClick={closeModal}
                        />
                        <Button
                            text="Save Preferences"
                            className="mb-4"
                            onClick={savePrefs}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}
