import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../store/context";
import Button from "../components/Elements/Button";
import Input from "../components/Elements/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAt,
    faCopy,
    faFingerprint,
    faLockAlt,
    faTimes,
    faUser,
    faUserCircle
} from "@fortawesome/pro-light-svg-icons";
import { faCheck, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import NKAPI from "../components/NKAPI";
import {
    faAmazon,
    faApple,
    faDiscord,
    faFacebook,
    faGooglePlay,
    faSteam,
    faTwitch
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import {
    faCheckCircle,
    faSpinnerThird
} from "@fortawesome/pro-duotone-svg-icons";
import DisplayNameEditModal from "../components/Modals/DisplayNameEditModal";
import EmailSettingsModal from "../components/Modals/EmailSettingsModal";
import GameCenter from "../images/game-center-128x128.png";
import EpicGames from "../images/EpicGames.svg";
import {
    faPhoneLaptop,
    faSignOut,
    faNewspaper,
    faUserSlash
} from "@fortawesome/pro-regular-svg-icons";
import LogoutModal from "../components/Modals/LogoutModal";
import { usePopper } from "react-popper";
import DeleteAccountModal from "../components/Modals/DeleteAccountModal";
import MarkedForDeletion from "../components/Modals/MarkedForDeletionModal";
import StatusRecoveryModal from "../components/Modals/StatusRecoveryModal";
import StatusConsentModal from "../components/Modals/StatusConsentModal";
import ChildUpgradeModal from "../components/Modals/ChildUpgradeModal";
import ConnectDiscord from "../components/Modals/ConnectDiscord";
import MarketingModal from "../components/Modals/MarketingModal";

export default function Profile() {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);
    // Local State
    const [emailFound, setEmailFound] = useState("");
    const [isMarketingModalIsOpen, setIsMarketingModalOpen] = useState(false);
    const [isLiNKCodeModalIsOpen, setIsLiNKCodeModalOpen] = useState(false);
    const [isRecoveryCodeOpen, setIsRecoveryCodeOpen] = useState(false);
    const [linkCode, setLinkCode] = useState("");
    const [recoveryCode, setRecoveryCode] = useState("");
    const [hasCopied, setHasCopied] = useState(false);
    const [isAdult, setIsAdult] = useState(false);
    const [isDisplayNameModalOpen, setIsDisplayNameModalOpen] = useState(false);
    const [isEmailSettingsModalOpen, setIsEmailSettingsModalOpen] =
        useState(false);
    const [isLinkEmailModalOpen, setIsLinkEmailModalOpen] = useState(false);
    const [linkEmailLoading, setLinkEmailLoading] = useState(false);
    const [linkEmailError, setLinkEmailError] = useState("");
    const [linkingProviderLoading, setLinkingProviderLoading] = useState("");
    const [connectedProviders, setConnectedProviders] = useState([]);
    const [supportedProviders, setSupportedProviders] = useState([]);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
        useState(false);
    const [isMarkedForDeletionModalOpen, setIsMarkedForDeletionModalOpen] =
        useState(false);
    const [isStatusRecoveryModalOpen, setIsStatusRecoveryModalOpen] =
        useState(false);
    const [isStatusConsentModalOpen, setIsStatusConsentModalOpen] =
        useState(false);
    const [isConnectDiscordOpen, setIsConnectDiscordOpen] = useState(false);
    const [connectDiscord, setConnectDiscord] = useState(false);
    const [isDiscordProvisional, setIsDiscordProvisional] = useState(false);
    let [isNewsletterChecked, setIsNewsletterChecked] = useState(false);
    let [isNKNewsChecked, setIsNKNewsChecked] = useState(false);

    let [isChildUpgradeModalOpen, setIsChildUpgradeModalOpen] = useState(false);

    const { t } = useTranslation(); // locale

    const navigate = useNavigate();

    // Popper
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "right",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 5]
                }
            },
            {
                name: "flip",
                options: {
                    fallbackPlacements: ["top"]
                }
            }
        ]
    });

    // Runs when connectDiscord changes
    useEffect(() => {
        if (connectDiscord) linkProvider("discord");
    }, [connectDiscord]);

    useEffect(() => {
        // If in non-full layout view, set to full
        if (!globalState.layoutFull) {
            globalDispatch({
                type: "layout/update",
                payload: {
                    layoutFull: true
                }
            });
        }

        // Get if account is adult or child
        if (
            globalState.user.providersAvailable.length === 1 &&
            globalState.user.providersAvailable[0] === "anon" &&
            globalState.user.age === 1
        ) {
            setIsAdult(false);
        } else {
            setIsAdult(true);
        }

        (async () => {
            // Find user's email, if has kloud provider
            if (globalState.user.providersAvailable.indexOf("kloud") > -1) {
                try {
                    let res = await NKAPI.request("/user/current/kloud", {});

                    let email = JSON.parse(res.data.data)?.email;
                    if (email) {
                        setEmailFound(email);

                        // Add email to global state, for contact page
                        let userObj = globalState.user;
                        userObj.email = email;
                        globalDispatch({
                            type: "user/update",
                            payload: {
                                user: userObj
                            }
                        });
                    }
                } catch (error) {
                    // fetch(
                    //     encodeURI(
                    //         `https://noti.ninja.kiwi/rossmarc/basic_message/message|Error loading kloud data - ${JSON.stringify(
                    //             error
                    //         )}/`
                    //     )
                    // );
                }

                // await NKAPI.request("/user/current/kloud", {})
                //     .then((d) => {
                //         let email = JSON.parse(d.data.data).email;
                //         setEmailFound(email);

                //         // Add email to global state, for contact page
                //         let userObj = globalState.user;
                //         userObj.email = email;
                //         globalDispatch({
                //             type: "user/update",
                //             payload: {
                //                 user: userObj
                //             }
                //         });
                //     })
                //     .catch(() => {
                //         setEmailFound("");
                //         // console.log(e);
                //     });
            } else {
                setEmailFound("");
            }

            // Check account status
            try {
                let res = await NKAPI.request("/user/consent/status", {});
                let accountStatuses = JSON.parse(res.data.data);

                // Account Flagged for Removal
                if (accountStatuses?.accountFlaggedForRemoval) {
                    setIsMarkedForDeletionModalOpen(true);
                }
                // Consent needs updating
                else if (
                    accountStatuses?.consentGiven === false &&
                    accountStatuses?.consentRequired === true
                ) {
                    setIsStatusConsentModalOpen(true);
                }
                // Show saftey modal
                else if (accountStatuses?.showRecoveryCodePrompt) {
                    setIsStatusRecoveryModalOpen(true);
                }
            } catch (error) {
                // fetch(
                //     encodeURI(
                //         `https://noti.ninja.kiwi/rossmarc/basic_message/message|Error loading status data/`
                //     )
                // );
            }

            // await NKAPI.request("/user/consent/status", {}).then((d) => {
            //     // console.log(JSON.parse(d.data.data));

            //     let accountStatuses = JSON.parse(d.data.data);

            //     // Account Flagged for Removal
            //     if (accountStatuses.accountFlaggedForRemoval) {
            //         setIsMarkedForDeletionModalOpen(true);
            //     }
            //     // Consent needs updating
            //     else if (
            //         accountStatuses.consentGiven === false &&
            //         accountStatuses.consentRequired === true
            //     ) {
            //         setIsStatusConsentModalOpen(true);
            //     }
            //     // Show saftey modal
            //     else if (accountStatuses.showRecoveryCodePrompt) {
            //         setIsStatusRecoveryModalOpen(true);
            //     }
            // });
        })();

        // Get connected providers for user
        let translatedProviders = NKAPI.translateProviders(
            globalState.user.providersAvailable
        );

        // Remove discord provider if provisional flag has been set
        if (NKAPI.getQueryVariable("discordProvisional")) {
            translatedProviders = translatedProviders.filter(
                (provider) => provider !== "discord"
            );
        }

        setConnectedProviders(translatedProviders);
        // Add connected providers to list of supported providers
        for (let index = 0; index < translatedProviders.length; index++) {
            const provider = translatedProviders[index];

            // Check connectedProvider is not already inside supportProviders, if not, add it
            if (NKAPI.availableProviders.indexOf(provider) === -1)
                NKAPI.availableProviders.push(provider);
        }
        setSupportedProviders(NKAPI.availableProviders);

        // Check link codes are enabled
        if (!globalState?.linkCodesEnabled) {
            // Check if LiNK Codes are enabled
            NKAPI.request("/user/recovery/code", {
                platform: NKAPI.platform ? NKAPI.platform : "ios"
            })
                .then((d) => {
                    let linkCodesEnabled = JSON.parse(d.data.data).enabled;
                    globalDispatch({
                        type: "ui/linkCodesEnabled",
                        payload: {
                            enabled: linkCodesEnabled
                        }
                    });
                })
                .catch(() => {
                    globalDispatch({
                        type: "ui/linkCodesEnabled",
                        payload: {
                            enabled: true
                        }
                    });
                });
        }

        if (!NKAPI.isAppLoaded) {
            NKAPI.isAppLoaded = true;
            NKAPI.sendToSDK("//loaded");
        }
        if (window.location.hostname.includes("staging")) {
            // fetch(
            //     encodeURI(
            //         `https://noti.ninja.kiwi/rossmarc/basic_message/message|Profile loaded/`
            //     )
            // );
        }

        // Show discord link popup
        if (
            NKAPI.availableProviders.includes("discord") &&
            NKAPI.getQueryVariable("connectDiscord")
        ) {
            setIsConnectDiscordOpen(true);
        }

        // eslint-disable-next-line
    }, []);

    // Provider Linkers
    function linkEmail(e) {
        e.preventDefault();

        let email = document.getElementById("linkEmail_Email").value.trim();
        let pass = document.getElementById("linkEmail_Pass").value;
        let passConf = document.getElementById("linkEmail_PassConf").value;
        // Validated email
        if (!email || !NKAPI.validateEmail(email)) {
            setLinkEmailError(t("error.valid_email"));
            return;
        }
        if (!pass) {
            setLinkEmailError(t("error.pass_req"));
            return;
        }
        if (pass !== passConf) {
            setLinkEmailError(t("error.pass_nomatch"));
            return;
        }

        setLinkEmailLoading(true);

        let marketing = {
            NEWS: isNewsletterChecked ? 1 : 0,
            MARKETING: isNKNewsChecked ? 1 : 0
        };

        NKAPI.request("/user/link/kloud/session", {
            email: email,
            password: pass,
            marketing
        })
            .then((d) => {
                setLinkEmailLoading(false);
                let user = JSON.parse(d.data.data).user;

                NKAPI.sendToSDK("//linked/", JSON.parse(d.data.data));

                // Kloud provider linked, add to connected list
                setConnectedProviders(connectedProviders.concat("kloud"));

                setIsLinkEmailModalOpen(false);

                // Update email
                setEmailFound(user.email);

                // Add email to global state, for contact page
                let userObj = globalState.user;
                userObj.email = user.email;
                globalDispatch({
                    type: "user/update",
                    payload: {
                        user: userObj
                    }
                });
            })
            .catch((e) => {
                setLinkEmailLoading(false);

                console.log(e?.response?.data?.error?.type);

                if (
                    e?.response?.data?.error?.type ===
                        "ERR_ACCOUNT_KLOUD_EMAIL_ALREADY_TAKEN" ||
                    e?.response?.data?.error?.type === "ERR_ALREADY_LINKED"
                ) {
                    setLinkEmailError(t("error.email_alreadyused"));
                } else if (
                    e?.response?.data?.error?.type ===
                    "ERR_ACCOUNT_KLOUD_INVALID_KLOUD_CREDENTIALS"
                ) {
                    setLinkEmailError(t("error.invalid_creds"));
                } else {
                    setLinkEmailError(t("error.error_tryagain"));
                }

                if (e?.toJSON()?.message === "Network Error") {
                    // No internet connection, show lost connection screen
                    globalDispatch({
                        type: "modal/offline",
                        payload: {
                            isShown: true
                        }
                    });
                }
            });
    }

    let providerInterval;
    let intervalTimeout = 15;
    let intervalCount = 0;
    function linkProvider(provider) {
        // Ignore if provider is already connected
        if (connectedProviders.indexOf(provider) > -1) return;

        // Let client SDK client handle certain providers - Use FB SDK if on mobile, else use browser flow
        let useFBSDK = provider === "facebook" && NKAPI.platform !== "steam";
        if (
            useFBSDK ||
            (provider !== "twitch" && provider !== "facebook") ||
            provider === "discord"
        ) {
            setLinkingProviderLoading(provider);
            NKAPI.sendToSDK("//linking?provider=" + provider);
            // Listen for client to finish with linking
            intervalCount = 0;
            providerInterval = setInterval(() => {
                linkProviderUpdateSDK(provider);
            }, 2000);
            return;
        }

        setLinkingProviderLoading(provider);
        NKAPI.request(`/user/${provider}/start`, {})
            .then((d) => {
                // Get oauth details
                let oauthData = JSON.parse(d.data.data);

                // Listen for user OAuth logins
                intervalCount = 0;
                providerInterval = setInterval(() => {
                    linkProviderUpdate(oauthData, provider);
                }, 2000);

                if (NKAPI.debug)
                    window.open(oauthData.facebookStartRedirectURL);
                else window.location = oauthData.facebookStartRedirectURL;
            })
            .catch((e) => {
                setLinkingProviderLoading("");

                if (e?.toJSON()?.message === "Network Error") {
                    // No internet connection, show lost connection screen
                    globalDispatch({
                        type: "modal/offline",
                        payload: {
                            isShown: true
                        }
                    });
                    clearInterval(providerInterval);
                }
            });
    }
    function linkProviderUpdate(oauthData, provider) {
        intervalCount++;
        // Check if errored
        if (hasLinkingErrored()) {
            intervalCount = 0;
            return;
        }

        NKAPI.request(`/user/${provider}/update`, {
            linkToken: oauthData.linkToken
        })
            .then((d) => {
                oauthData = JSON.parse(d.data.data);

                if (oauthData.facebookToken !== "NONE") {
                    clearInterval(providerInterval);

                    // Complete oauth
                    NKAPI.request(`/user/link/${provider}/session`, {
                        accessToken: oauthData.facebookToken
                    })
                        .then(() => {
                            linkingComplete(provider, true);
                        })
                        .catch((e) => {
                            setLinkingProviderLoading("");

                            if (
                                e?.response?.data?.error?.type ===
                                "ERR_USER_CANNOT_LINK_ALREADY_EXISTS"
                            ) {
                                globalDispatch({
                                    type: "modal/error",
                                    payload: {
                                        isShown: true,
                                        errorMsg: "error.provider_exists"
                                    }
                                });
                            }
                        });
                }
            })
            .catch((e) => {
                setLinkingProviderLoading("");
                clearInterval(providerInterval);
                // console.log(e.response);
                if (
                    e?.response?.data?.error?.type ===
                    "ERR_USER_CANNOT_LINK_ALREADY_EXISTS"
                ) {
                    globalDispatch({
                        type: "modal/error",
                        payload: {
                            isShown: true,
                            errorMsg: "error.provider_exists"
                        }
                    });
                }
            });
    }
    function linkProviderUpdateSDK(provider) {
        intervalCount++;
        // Check if errored
        if (hasLinkingErrored()) {
            intervalCount = 0;
            return;
        }

        linkingComplete(provider);
    }

    function hasLinkingErrored() {
        // Check if errored
        if (NKAPI.hasProviderErrored) {
            NKAPI.hasProviderErrored = false;

            setLinkingProviderLoading("");
            clearInterval(providerInterval);

            // Show error
            globalDispatch({
                type: "modal/error",
                payload: {
                    isShown: true,
                    errorMsg:
                        NKAPI.errorFromClient ===
                        "ERR_SDK_USER_CANNOT_FIND_LINK"
                            ? "error.provider_exists"
                            : null
                }
            });

            return true;
        }

        // Check if linking has timed out
        if (intervalCount >= intervalTimeout) {
            NKAPI.hasProviderErrored = false;
            // Cancel spinner
            setLinkingProviderLoading("");
            clearInterval(providerInterval);

            // Show error
            globalDispatch({
                type: "modal/error",
                payload: {
                    isShown: true,
                    errorMsg: "error.login_timedout"
                }
            });

            return true;
        }

        // No error and no timeout, go again
        return false;
    }

    function linkingComplete(provider, sendIntercept) {
        // Get latest session - Client will inject the new sessionID: See App.js
        NKAPI.request("/user/current", {}).then((d) => {
            let userData = JSON.parse(d.data.data);

            // Replace apple or twitch id
            let translatedProviders = NKAPI.translateProviders(
                userData.user.providersAvailable
            );

            // Check new provider has been enabled
            if (translatedProviders.indexOf(provider) > -1) {
                clearInterval(providerInterval);
                setLinkingProviderLoading("");
                // Update state
                setConnectedProviders(connectedProviders.concat(provider));

                // Tell client provider has been linked, only for non-client providers (kloud, twitch, etc..)
                if (sendIntercept) {
                    NKAPI.sendToSDK("//linked/", userData);
                }
            }
            // If no provider found, interval will run update function again and again until timed out or errored
        });
    }

    return (
        <div className="w-full flex flex-col md:flex-row px-1">
            {/* Profile Details */}
            <div className="flex-1">
                <h1 className="text-2xl lg:text-3xl font-semibold mb-2 lg:mb-4 text-white">
                    {t("profile.details")}
                </h1>

                <div className="flex flex-col space-y-4">
                    {/* Display Name - Display & Edit */}
                    <Input
                        icon={faUserCircle}
                        value={globalState?.user?.displayName}
                        readOnly={true}
                        editModal={isAdult ? true : false}
                        isEditModalOpen={
                            isAdult ? setIsDisplayNameModalOpen : null
                        }
                    />
                    {isDisplayNameModalOpen ? (
                        <DisplayNameEditModal
                            isModalOpen={isDisplayNameModalOpen}
                            setIsModalOpen={setIsDisplayNameModalOpen}
                        />
                    ) : null}

                    {/* Shortcode */}
                    <Input
                        icon={faFingerprint}
                        value={globalState?.user?.shortcode}
                        readOnly={true}
                        label={t("profile.label_userid")}
                    />

                    {/* Email */}
                    {emailFound && isAdult && (
                        <>
                            <Input
                                icon={faAt}
                                value={emailFound}
                                readOnly={true}
                                editModal={true}
                                isEditModalOpen={setIsEmailSettingsModalOpen}
                            />
                            {isEmailSettingsModalOpen ? (
                                <EmailSettingsModal
                                    isModalOpen={isEmailSettingsModalOpen}
                                    setIsModalOpen={setIsEmailSettingsModalOpen}
                                    email={emailFound}
                                    setEmail={setEmailFound}
                                />
                            ) : null}
                        </>
                    )}

                    {/* Marketing Preferences */}
                    {(NKAPI.isStaging() || NKAPI.isDev()) && (
                        <Button
                            div={
                                <div className="flex-center text-white pointer-events-none">
                                    <p className="font-semibold tracking-wide text-base lg:text-lg leading-4">
                                        {/* TODO: Translate */}
                                        Newsletter
                                    </p>
                                    <FontAwesomeIcon
                                        className="text-2xl ml-2"
                                        icon={faNewspaper}
                                    />
                                </div>
                            }
                            // TODO: Translate
                            // infoText={t("profile.linkcode_infotext")}
                            onClick={() => {
                                setIsMarketingModalOpen(true);
                            }}
                        />
                    )}

                    {/* LiNK Code */}
                    {globalState.linkCodesEnabled && (
                        <Button
                            text={t("profile.linkcode")}
                            infoText={t("profile.linkcode_infotext")}
                            onClick={() => {
                                setIsLiNKCodeModalOpen(true);
                            }}
                        />
                    )}

                    {/* Recovery code - Child acc only */}
                    {!isAdult && (
                        <Button
                            text={t("profile.recovery")}
                            infoText={t("profile.recovery_infotext")}
                            onClick={() => {
                                setIsRecoveryCodeOpen(true);
                            }}
                        />
                    )}

                    {/* Logout */}
                    <Button
                        div={
                            <div className="flex-center text-white pointer-events-none">
                                <p className="font-semibold tracking-wide text-base lg:text-lg leading-4">
                                    {t("common.logout")}
                                </p>
                                <FontAwesomeIcon
                                    className="text-2xl ml-2"
                                    icon={faSignOut}
                                />
                            </div>
                        }
                        onClick={() => {
                            if (
                                NKAPI.appID === 17 ||
                                NKAPI.appID === 11 ||
                                NKAPI.appID === 21
                            )
                                NKAPI.sendToSDK("//logout");
                            else if (
                                window.location.hostname === "localhost" &&
                                !NKAPI.platform
                            ) {
                                globalDispatch({
                                    type: "user/logout"
                                });
                                navigate("/");
                            } else setIsLogoutModalOpen(true);
                        }}
                    />
                    {isLogoutModalOpen ? (
                        <LogoutModal
                            isModalOpen={isLogoutModalOpen}
                            setIsModalOpen={setIsLogoutModalOpen}
                        />
                    ) : null}

                    {/* Delete Account */}
                    <button
                        className="w-3/4 lg:w-1/2 px-2 mx-auto select-none flex-center rounded-full h-12 lg:h-14 relative focus:outline-none focus:ring-2 focus:ring-nk focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-gray-600 flex-shrink-0 bg-gray-700 text-nk"
                        onClick={() => {
                            setIsDeleteAccountModalOpen(true);
                        }}
                    >
                        <p className="font-semibold tracking-wide text-base lg:text-lg leading-4">
                            {t("deleteaccount.submit")}
                        </p>
                        <FontAwesomeIcon
                            className="text-2xl ml-2"
                            icon={faUserSlash}
                        />
                    </button>
                    {isDeleteAccountModalOpen ? (
                        <DeleteAccountModal
                            isModalOpen={isDeleteAccountModalOpen}
                            setIsModalOpen={setIsDeleteAccountModalOpen}
                        />
                    ) : null}
                </div>
            </div>

            {/* Divider */}
            <div className="flex items-center my-4 md:my-0">
                <div className="bg-gray-600 flex-1 h-1 rounded md:hidden"></div>
                <div className="text-gray-500 text-xl uppercase tracking-widest font-semibold px-6 lg:px-12">
                    {t("common.or")}
                </div>
                <div className="bg-gray-600 flex-1 h-1 rounded md:hidden"></div>
            </div>

            {/* Connected Providers */}
            <div className="flex-1">
                <h1 className="text-2xl lg:text-3xl font-semibold mb-2 lg:mb-4 md:text-right text-white">
                    {t("profile.providers")}
                </h1>

                {/* Providers - Hide on child accounts TODO: Componterise - */}
                {isAdult ? (
                    <div className="flex flex-col space-y-4">
                        <button
                            className="select-none flex-center rounded-full h-12 lg:h-14 relative bg-nk focus:outline-none focus:ring-nk focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-opacity-90"
                            onClick={() => {
                                if (connectedProviders.indexOf("kloud") === -1)
                                    setIsLinkEmailModalOpen(true);
                            }}
                        >
                            <div className="flex w-full items-center">
                                <FontAwesomeIcon
                                    className=" text-white text-2xl lg:text-3xl ml-4 mr-3 flex-shrink-0"
                                    icon={faEnvelope}
                                />
                                <p className="text-white text-left text-lg lg:text-xl font-semibold w-full">
                                    {t("common.email")}
                                </p>

                                <FontAwesomeIcon
                                    className=" text-white text-xl mr-4 flex-shrink-0"
                                    icon={faPhoneLaptop}
                                />

                                {connectedProviders.indexOf("kloud") > -1 ? (
                                    <div className="flex flex-shrink-0 mr-4 text-white items-center">
                                        <p className="text-base lg:text-lg">
                                            {t("profile.connected")}
                                        </p>
                                        <FontAwesomeIcon
                                            className="text-xl lg:text-2xl ml-3"
                                            icon={faCheckCircle}
                                        />
                                    </div>
                                ) : (
                                    <p className="text-base lg:text-lg text-white opacity-70 flex-shrink-0 mr-4">
                                        {t("profile.not_connected")}
                                    </p>
                                )}
                            </div>
                        </button>
                        <Modal
                            isOpen={isLinkEmailModalOpen}
                            onRequestClose={() => {
                                setIsLinkEmailModalOpen(false);
                                setLinkEmailError("");
                            }}
                            contentLabel="Connect Email"
                            className="2xl:w-4/12 md:w-6/12 sm:w-8/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
                            overlayClassName="fixed w-full h-screen bg-black bg-opacity-60 top-0 flex-center"
                        >
                            <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                                {/* Modal Header */}
                                <div className="h-20 flex justify-between items-center px-1">
                                    <h2 className="text-xl lg:text-3xl font-semibold">
                                        {t("profile.email_title")}
                                    </h2>
                                    <FontAwesomeIcon
                                        className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                                        icon={faTimes}
                                        onClick={() => {
                                            setIsLinkEmailModalOpen(false);
                                            setLinkEmailError("");
                                        }}
                                    />
                                </div>

                                {/* Modal Content */}
                                <div className="flex-1 overflow-y-auto p-1">
                                    <form
                                        onSubmit={linkEmail}
                                        className="space-y-3"
                                    >
                                        <Input
                                            icon={faUser}
                                            placeholder={t("common.email")}
                                            type="email"
                                            id="linkEmail_Email"
                                            onChange={() => {
                                                setLinkEmailError("");
                                            }}
                                        />
                                        <Input
                                            icon={faLockAlt}
                                            placeholder={t("common.pass")}
                                            type="password"
                                            id="linkEmail_Pass"
                                            onChange={() => {
                                                setLinkEmailError("");
                                            }}
                                            autocomplete="current-password"
                                        />
                                        <Input
                                            icon={faLockAlt}
                                            placeholder={t(
                                                "common.pass_confirm"
                                            )}
                                            type="password"
                                            id="linkEmail_PassConf"
                                            onChange={() => {
                                                setLinkEmailError("");
                                            }}
                                            autocomplete="current-password"
                                        />

                                        {/* Marketing TODO: Translate */}
                                        <div className="flex flex-col">
                                            <h1 className="mb-2 lg:text-lg font-bold">
                                                Newsletter
                                            </h1>

                                            <p className="text-sm lg:text-base mb-4">
                                                By subscribing to our
                                                newsletter, we'll keep you in
                                                the loop on all the latest
                                                releases, updates, and game
                                                launches!
                                            </p>

                                            <div className="flex items-center flex-wrap">
                                                <div
                                                    className={`h-12 w-12 lg:h-14 lg:w-14 rounded-full flex-center flex-shrink-0  cursor-pointer ${
                                                        isNewsletterChecked
                                                            ? "bg-nk hover:bg-nk-600 ring-2 ring-nk ring-offset-gray-800 ring-offset-2"
                                                            : "bg-gray-600 hover:bg-gray-400"
                                                    }`}
                                                    id="signUp_legal"
                                                    onClick={() => {
                                                        setIsNewsletterChecked(
                                                            !isNewsletterChecked
                                                        );
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        className={`pointer-events-none text-2xl text-white ${
                                                            isNewsletterChecked
                                                                ? "visible"
                                                                : "hidden"
                                                        }`}
                                                        icon={faCheck}
                                                    />
                                                </div>
                                                <p className="text-gray-200 flex-1 text-sm lg:text-lg pl-2 cursor-pointer leading-5 lg:leading-4 mr-2">
                                                    <span
                                                        onClick={() => {
                                                            setIsNewsletterChecked(
                                                                !isNewsletterChecked
                                                            );
                                                        }}
                                                    >
                                                        {/* TODO: translate  */}
                                                        Game Update Newsletter
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="flex items-center flex-wrap mt-4">
                                                <div
                                                    className={`h-12 w-12 lg:h-14 lg:w-14 rounded-full flex-center flex-shrink-0  cursor-pointer ${
                                                        isNKNewsChecked
                                                            ? "bg-nk hover:bg-nk-600 ring-2 ring-nk ring-offset-gray-800 ring-offset-2"
                                                            : "bg-gray-600 hover:bg-gray-400"
                                                    }`}
                                                    id="signUp_legal"
                                                    onClick={() => {
                                                        setIsNKNewsChecked(
                                                            !isNKNewsChecked
                                                        );
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        className={`pointer-events-none text-2xl text-white ${
                                                            isNKNewsChecked
                                                                ? "visible"
                                                                : "hidden"
                                                        }`}
                                                        icon={faCheck}
                                                    />
                                                </div>
                                                <p className="text-gray-200 flex-1 text-sm lg:text-lg pl-2 cursor-pointer leading-5 lg:leading-4 mr-2">
                                                    <span
                                                        onClick={() => {
                                                            setIsNKNewsChecked(
                                                                !isNKNewsChecked
                                                            );
                                                        }}
                                                    >
                                                        {/* TODO: translate  */}
                                                        Ninja Kiwi Related News
                                                    </span>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex items-center">
                                            <Button
                                                color="nk"
                                                text={t("common.submit")}
                                                type="submit"
                                                spinner={linkEmailLoading}
                                                className="w-44"
                                            />
                                            {linkEmailError && (
                                                <div className="bg-nk rounded-full text-white font-semibold text-xs lg:text-sm px-2 lg:px-4 py-1 ml-4">
                                                    {linkEmailError}
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>

                        {supportedProviders.indexOf("steam") > -1 && (
                            <button
                                className="select-none flex-center rounded-full h-12 lg:h-14 relative bg-steam focus:outline-none focus:ring-steam focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-[#283144]"
                                onClick={() => {
                                    linkProvider("steam");
                                }}
                            >
                                {linkingProviderLoading === "steam" ? (
                                    <FontAwesomeIcon
                                        className=" text-white text-2xl fa-spin"
                                        icon={faSpinnerThird}
                                    />
                                ) : (
                                    <div className="flex w-full items-center">
                                        <FontAwesomeIcon
                                            className=" text-white text-2xl lg:text-3xl ml-4 mr-3 flex-shrink-0"
                                            icon={faSteam}
                                        />
                                        <p className="text-white text-left text-lg lg:text-xl font-semibold w-full">
                                            Steam
                                        </p>
                                        {connectedProviders.indexOf("steam") >
                                        -1 ? (
                                            <div className="flex flex-shrink-0 mr-4 text-white items-center">
                                                <p className="text-base lg:text-lg">
                                                    {t("profile.connected")}
                                                </p>
                                                <FontAwesomeIcon
                                                    className="text-xl lg:text-2xl ml-3"
                                                    icon={faCheckCircle}
                                                />
                                            </div>
                                        ) : (
                                            <p className="text-base lg:text-lg text-white opacity-70 flex-shrink-0 mr-4">
                                                {t("profile.not_connected")}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </button>
                        )}

                        {supportedProviders.indexOf("twitch") > -1 && (
                            <button
                                className="select-none flex-center rounded-full h-12 lg:h-14 relative bg-twitch focus:outline-none focus:ring-twitch focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-opacity-90"
                                onClick={() => {
                                    linkProvider("twitch");
                                }}
                            >
                                {linkingProviderLoading === "twitch" ? (
                                    <FontAwesomeIcon
                                        className=" text-white text-2xl fa-spin"
                                        icon={faSpinnerThird}
                                    />
                                ) : (
                                    <div className="flex w-full items-center">
                                        <FontAwesomeIcon
                                            className=" text-white text-2xl lg:text-3xl ml-4 mr-3 flex-shrink-0"
                                            icon={faTwitch}
                                        />
                                        <p className="text-white text-left text-lg lg:text-xl font-semibold w-full">
                                            Twitch
                                        </p>

                                        <FontAwesomeIcon
                                            className=" text-white text-xl mr-4 flex-shrink-0"
                                            icon={faPhoneLaptop}
                                        />
                                        {connectedProviders.indexOf("twitch") >
                                        -1 ? (
                                            <div className="flex flex-shrink-0 mr-4 text-white items-center">
                                                <p className="text-base lg:text-lg">
                                                    {t("profile.connected")}
                                                </p>
                                                <FontAwesomeIcon
                                                    className="text-xl lg:text-2xl ml-3"
                                                    icon={faCheckCircle}
                                                />
                                            </div>
                                        ) : (
                                            <p className="text-base lg:text-lg text-white opacity-70 flex-shrink-0 mr-4">
                                                {t("profile.not_connected")}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </button>
                        )}

                        {connectedProviders.indexOf("facebook") > -1 && (
                            <button
                                className="select-none flex-center rounded-full h-12 lg:h-14 relative bg-facebook focus:outline-none focus:ring-facebook focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-opacity-90"
                                onClick={() => {
                                    linkProvider("facebook");
                                }}
                            >
                                {linkingProviderLoading === "facebook" ? (
                                    <FontAwesomeIcon
                                        className=" text-white text-2xl fa-spin"
                                        icon={faSpinnerThird}
                                    />
                                ) : (
                                    <div className="flex w-full items-center">
                                        <FontAwesomeIcon
                                            className=" text-white text-2xl lg:text-3xl ml-4 mr-3 flex-shrink-0"
                                            icon={faFacebook}
                                        />
                                        <p className="text-white text-left text-lg lg:text-xl font-semibold w-full">
                                            Facebook
                                        </p>

                                        <FontAwesomeIcon
                                            className=" text-white text-xl mr-4 flex-shrink-0"
                                            icon={faPhoneLaptop}
                                        />
                                        {connectedProviders.indexOf(
                                            "facebook"
                                        ) > -1 ? (
                                            <div className="flex flex-shrink-0 mr-4 text-white items-center">
                                                <p className="text-base lg:text-lg">
                                                    {t("profile.connected")}
                                                </p>
                                                <FontAwesomeIcon
                                                    className="text-xl lg:text-2xl ml-3"
                                                    icon={faCheckCircle}
                                                />
                                            </div>
                                        ) : (
                                            <p className="text-base lg:text-lg text-white opacity-70 flex-shrink-0 mr-4">
                                                {t("profile.not_connected")}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </button>
                        )}

                        {supportedProviders.indexOf("apple") > -1 && (
                            <button
                                className="select-none flex-center rounded-full h-12 lg:h-14 relative bg-apple focus:outline-none focus:ring-apple focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-opacity-90"
                                onClick={() => {
                                    linkProvider("apple");
                                }}
                            >
                                {linkingProviderLoading === "apple" ? (
                                    <FontAwesomeIcon
                                        className=" text-white text-2xl fa-spin"
                                        icon={faSpinnerThird}
                                    />
                                ) : (
                                    <div className="flex w-full items-center">
                                        <FontAwesomeIcon
                                            className=" text-white text-2xl lg:text-3xl ml-4 mr-3 flex-shrink-0"
                                            icon={faApple}
                                        />
                                        <p className="text-white text-left text-lg lg:text-xl font-semibold w-full">
                                            {t("onboarding.apple_signin")}
                                        </p>
                                        {connectedProviders.indexOf("apple") >
                                        -1 ? (
                                            <div className="flex flex-shrink-0 mr-4 text-white items-center">
                                                <p className="text-base lg:text-lg">
                                                    {t("profile.connected")}
                                                </p>
                                                <FontAwesomeIcon
                                                    className="text-xl lg:text-2xl ml-3"
                                                    icon={faCheckCircle}
                                                />
                                            </div>
                                        ) : (
                                            <p className="text-base lg:text-lg text-white opacity-70 flex-shrink-0 mr-4">
                                                {t("profile.not_connected")}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </button>
                        )}

                        {supportedProviders.indexOf("googleplay") > -1 && (
                            <button
                                className="select-none flex-center rounded-full h-12 lg:h-14 relative bg-googleplay focus:outline-none focus:ring-googleplay focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-opacity-90"
                                onClick={() => {
                                    linkProvider("googleplay");
                                }}
                            >
                                {linkingProviderLoading === "googleplay" ? (
                                    <FontAwesomeIcon
                                        className=" text-white text-2xl fa-spin"
                                        icon={faSpinnerThird}
                                    />
                                ) : (
                                    <div className="flex w-full items-center">
                                        <FontAwesomeIcon
                                            className=" text-white text-2xl lg:text-3xl ml-4 mr-3 flex-shrink-0"
                                            icon={faGooglePlay}
                                        />
                                        <p className="text-white text-left text-lg lg:text-xl font-semibold w-full">
                                            Google Play
                                        </p>
                                        {connectedProviders.indexOf(
                                            "googleplay"
                                        ) > -1 ? (
                                            <div className="flex flex-shrink-0 mr-4 text-white items-center">
                                                <p className="text-base lg:text-lg">
                                                    {t("profile.connected")}
                                                </p>
                                                <FontAwesomeIcon
                                                    className="text-xl lg:text-2xl ml-3"
                                                    icon={faCheckCircle}
                                                />
                                            </div>
                                        ) : (
                                            <p className="text-base lg:text-lg text-white opacity-70 flex-shrink-0 mr-4">
                                                {t("profile.not_connected")}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </button>
                        )}

                        {supportedProviders.indexOf("gamecenter") > -1 && (
                            <button
                                className="select-none flex-center rounded-full h-12 lg:h-14 relative bg-gamecenter focus:outline-none focus:ring-gamecenter focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-opacity-90"
                                onClick={() => {
                                    linkProvider("gamecenter");
                                }}
                            >
                                {linkingProviderLoading === "gamecenter" ? (
                                    <FontAwesomeIcon
                                        className=" text-white text-2xl fa-spin"
                                        icon={faSpinnerThird}
                                    />
                                ) : (
                                    <div className="flex w-full items-center">
                                        <img
                                            className="h-7 w-7 lg:h-9 lg:w-9 text-white ml-4 mr-3 left-0"
                                            src={GameCenter}
                                            alt="Game Center"
                                        ></img>
                                        <p className="text-white text-left text-lg lg:text-xl font-semibold w-full">
                                            Game Center
                                        </p>
                                        {connectedProviders.indexOf(
                                            "gamecenter"
                                        ) > -1 ? (
                                            <div className="flex flex-shrink-0 mr-4 text-white items-center">
                                                <p className="text-base lg:text-lg">
                                                    {t("profile.connected")}
                                                </p>
                                                <FontAwesomeIcon
                                                    className="text-xl lg:text-2xl ml-3"
                                                    icon={faCheckCircle}
                                                />
                                            </div>
                                        ) : (
                                            <p className="text-base lg:text-lg text-white opacity-70 flex-shrink-0 mr-4">
                                                {t("profile.not_connected")}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </button>
                        )}

                        {supportedProviders.indexOf("gamecircle") > -1 && (
                            <button
                                className="select-none flex-center rounded-full h-12 lg:h-14 relative bg-amazon focus:outline-none focus:ring-amazon focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-opacity-90"
                                onClick={() => {
                                    linkProvider("gamecircle");
                                }}
                            >
                                {linkingProviderLoading === "gamecircle" ? (
                                    <FontAwesomeIcon
                                        className=" text-white text-2xl fa-spin"
                                        icon={faSpinnerThird}
                                    />
                                ) : (
                                    <div className="flex w-full items-center">
                                        <FontAwesomeIcon
                                            className=" text-white text-2xl lg:text-3xl ml-4 mr-3 flex-shrink-0"
                                            icon={faAmazon}
                                        />
                                        <p className="text-white text-left text-lg lg:text-xl font-semibold w-full">
                                            GameCircle
                                        </p>
                                        {connectedProviders.indexOf(
                                            "gamecircle"
                                        ) > -1 ? (
                                            <div className="flex flex-shrink-0 mr-4 text-white items-center">
                                                <p className="text-base lg:text-lg">
                                                    {t("profile.connected")}
                                                </p>
                                                <FontAwesomeIcon
                                                    className="text-xl lg:text-2xl ml-3"
                                                    icon={faCheckCircle}
                                                />
                                            </div>
                                        ) : (
                                            <p className="text-base lg:text-lg text-white opacity-70 flex-shrink-0 mr-4">
                                                {t("profile.not_connected")}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </button>
                        )}

                        {supportedProviders.indexOf("epicgames") > -1 && (
                            <button
                                className="select-none flex-center rounded-full h-12 lg:h-14 relative bg-epicgames focus:outline-none focus:ring-epicgames focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-opacity-90"
                                onClick={() => {
                                    // linkProvider("gamecircle");
                                }}
                            >
                                {linkingProviderLoading === "epicgames" ? (
                                    <FontAwesomeIcon
                                        className=" text-white text-2xl fa-spin"
                                        icon={faSpinnerThird}
                                    />
                                ) : (
                                    <div className="flex w-full items-center">
                                        <img
                                            className="h-7 w-7 lg:h-9 lg:w-9 text-white ml-4 mr-3 left-0"
                                            src={EpicGames}
                                            alt="Epic Games"
                                        ></img>
                                        <p className="text-white text-left text-lg lg:text-xl font-semibold w-full">
                                            Epic Games
                                        </p>
                                        {connectedProviders.indexOf(
                                            "epicgames"
                                        ) > -1 ? (
                                            <div className="flex flex-shrink-0 mr-4 text-white items-center">
                                                <p className="text-base lg:text-lg">
                                                    {t("profile.connected")}
                                                </p>
                                                <FontAwesomeIcon
                                                    className="text-xl lg:text-2xl ml-3"
                                                    icon={faCheckCircle}
                                                />
                                            </div>
                                        ) : (
                                            <p className="text-base lg:text-lg text-white opacity-70 flex-shrink-0 mr-4">
                                                {t("profile.not_connected")}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </button>
                        )}

                        {supportedProviders.indexOf("discord") > -1 &&
                            (NKAPI.isStaging || NKAPI.isDev) && (
                                <button
                                    className="select-none flex-center rounded-full h-12 lg:h-14 relative bg-discord focus:outline-none focus:ring-discord focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-opacity-90"
                                    onClick={() => {
                                        linkProvider("discord");
                                    }}
                                >
                                    {linkingProviderLoading === "discord" ? (
                                        <FontAwesomeIcon
                                            className=" text-white text-2xl fa-spin"
                                            icon={faSpinnerThird}
                                        />
                                    ) : (
                                        <div className="flex w-full items-center">
                                            <FontAwesomeIcon
                                                className=" text-white text-2xl lg:text-3xl ml-4 mr-3 flex-shrink-0"
                                                icon={faDiscord}
                                            />
                                            <p className="text-white text-left text-lg lg:text-xl font-semibold w-full">
                                                Discord
                                            </p>
                                            {connectedProviders.indexOf(
                                                "discord"
                                            ) > -1 ? (
                                                <div className="flex flex-shrink-0 mr-4 text-white items-center">
                                                    <p className="text-base lg:text-lg">
                                                        {t("profile.connected")}
                                                    </p>
                                                    <FontAwesomeIcon
                                                        className="text-xl lg:text-2xl ml-3"
                                                        icon={faCheckCircle}
                                                    />
                                                </div>
                                            ) : (
                                                <p className="text-base lg:text-lg text-white opacity-70 flex-shrink-0 mr-4">
                                                    {t("profile.not_connected")}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </button>
                            )}

                        <div className="flex-center space-x-2 text-gray-300">
                            <FontAwesomeIcon
                                className="text-xl"
                                icon={faPhoneLaptop}
                            />
                            <p>- {t("common.supports_crossplatform")}</p>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="mt-4 w-full border-blue-500 bg-blue-600 text-gray-100 border-2 rounded-md p-4">
                            <div className="text-base">
                                <p className="text-center">
                                    {t("common.feature_disabledchild")}
                                </p>
                            </div>
                        </div>
                        {isChildUpgradeModalOpen ? (
                            <ChildUpgradeModal
                                isModalOpen={isChildUpgradeModalOpen}
                                setIsModalOpen={setIsChildUpgradeModalOpen}
                            />
                        ) : null}
                    </>
                )}
            </div>

            {/* Generate LiNK Code Modal TODO: Move into components */}
            {globalState.linkCodesEnabled && (
                <Modal
                    isOpen={isLiNKCodeModalIsOpen}
                    onRequestClose={() => {
                        setIsLiNKCodeModalOpen(false);
                        setLinkCode("");
                        setHasCopied(false);
                    }}
                    onAfterOpen={() => {
                        // Get code from api
                        NKAPI.request("/user/create/token", {})
                            .then((d) => {
                                let linkCode = JSON.parse(d.data.data).token;

                                // Show code to user
                                setLinkCode(
                                    `${linkCode.substring(
                                        0,
                                        5
                                    )}-${linkCode.substring(5)}`
                                );
                            })
                            .catch((e) => {
                                if (e?.toJSON()?.message === "Network Error") {
                                    // No internet connection, show lost connection screen
                                    globalDispatch({
                                        type: "modal/offline",
                                        payload: {
                                            isShown: true
                                        }
                                    });
                                }
                            });
                    }}
                    contentLabel="Create LiNK Code"
                    className="2xl:w-4/12 md:w-6/12 sm:w-8/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
                    overlayClassName="fixed w-full h-screen bg-black bg-opacity-60 top-0 flex-center"
                >
                    <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                        {/* Modal Header */}
                        <div className="h-20 flex justify-between items-center px-1">
                            <h2 className="text-xl lg:text-3xl font-semibold">
                                LiNK Code
                            </h2>
                            <FontAwesomeIcon
                                className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                                icon={faTimes}
                                onClick={() => {
                                    setIsLiNKCodeModalOpen(false);
                                    setLinkCode("");
                                    setHasCopied(false);
                                }}
                            />
                        </div>

                        {/* Modal Content */}
                        <div className="flex-1 overflow-y-auto p-1">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: t("profile.linkcode_intro")
                                }}
                            ></p>

                            {!linkCode ? (
                                <div className="flex-center w-full py-8">
                                    <FontAwesomeIcon
                                        className="text-3xl fa-spin text-nk"
                                        icon={faSpinnerThird}
                                    />
                                </div>
                            ) : (
                                <div className="flex-center w-full py-7 relative flex-wrap">
                                    <p className="text-2xl lg:text-4xl tracking-widest">
                                        {linkCode}
                                    </p>

                                    <div
                                        className="group flex-center ml-2 cursor-pointer relative"
                                        ref={setReferenceElement}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                linkCode
                                            );
                                            NKAPI.sendToSDK(
                                                "//copytext",
                                                linkCode
                                            );
                                            setHasCopied(true);
                                        }}
                                    >
                                        <div className="h-10 w-10 bg-gray-600 rounded-full opacity-0 group-hover:opacity-100"></div>
                                        <FontAwesomeIcon
                                            className="absolute text-xl text-gray-200 group-hover:text-nk"
                                            icon={faCopy}
                                        />

                                        {hasCopied && (
                                            <div
                                                ref={setPopperElement}
                                                style={styles.popper}
                                                {...attributes.popper}
                                                className=" bg-nk bg-opacity-80 rounded-full text-white font-semibold text-xs lg:text-sm px-4 py-1"
                                            >
                                                {t("common.copied")}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
            )}

            {/* View Recovery Code Modal TODO: Check sizing when on right monitor (slim) */}
            <Modal
                isOpen={isRecoveryCodeOpen}
                onRequestClose={() => {
                    setIsRecoveryCodeOpen(false);
                    setHasCopied(false);
                }}
                onAfterOpen={() => {
                    if (!recoveryCode) {
                        // Get code from api
                        NKAPI.request("/user/recovery", {})
                            .then((d) => {
                                setRecoveryCode(
                                    JSON.parse(d.data.data).recovery
                                );
                            })
                            .catch((e) => {
                                if (e?.toJSON()?.message === "Network Error") {
                                    // No internet connection, show lost connection screen
                                    globalDispatch({
                                        type: "modal/offline",
                                        payload: {
                                            isShown: true
                                        }
                                    });
                                }
                            });
                    }
                }}
                contentLabel="View Recovery Code"
                className="2xl:w-4/12 md:w-6/12 sm:w-8/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
                overlayClassName="fixed w-full h-screen bg-black bg-opacity-60 top-0 flex-center"
            >
                <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                    {/* Modal Header */}
                    <div className="h-20 flex justify-between items-center px-1">
                        <h2 className="text-xl lg:text-3xl font-semibold">
                            {t("profile.recovery_title")}
                        </h2>
                        <FontAwesomeIcon
                            className="text-gray-400 text-3xl lg:text-5xl cursor-pointer hover:text-white"
                            icon={faTimes}
                            onClick={() => {
                                setIsRecoveryCodeOpen(false);
                                setHasCopied(false);
                            }}
                        />
                    </div>

                    {/* Modal Content */}
                    <div className="flex-1 overflow-y-auto p-1">
                        <p>{t("profile.recovery_intro")}</p>

                        {!recoveryCode ? (
                            <div className="flex-center w-full py-8">
                                <FontAwesomeIcon
                                    className="text-3xl fa-spin text-nk"
                                    icon={faSpinnerThird}
                                />
                            </div>
                        ) : (
                            <div className="flex-center w-full py-7 relative flex-wrap">
                                <p className="text-2xl lg:text-4xl tracking-widest">
                                    {recoveryCode}
                                </p>

                                <div
                                    className="group flex-center ml-2 cursor-pointer relative"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            recoveryCode
                                        );
                                        NKAPI.sendToSDK(
                                            "//copytext",
                                            recoveryCode
                                        );
                                        setHasCopied(true);
                                    }}
                                >
                                    <div className="h-10 w-10 bg-gray-600 rounded-full opacity-0 group-hover:opacity-100"></div>
                                    <FontAwesomeIcon
                                        className="absolute text-xl text-gray-200 group-hover:text-nk"
                                        icon={faCopy}
                                    />

                                    {hasCopied && (
                                        <div className="absolute left-12 bg-nk bg-opacity-80  rounded-full text-white font-semibold text-xs lg:text-sm px-4 py-1 mr-4">
                                            {t("common.copied")}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>

            {isMarketingModalIsOpen ? (
                <MarketingModal
                    isModalOpen={isMarketingModalIsOpen}
                    setIsModalOpen={setIsMarketingModalOpen}
                />
            ) : null}

            {isMarkedForDeletionModalOpen ? (
                <MarkedForDeletion
                    isModalOpen={isMarkedForDeletionModalOpen}
                    setIsModalOpen={setIsMarkedForDeletionModalOpen}
                />
            ) : null}

            {isStatusRecoveryModalOpen ? (
                <StatusRecoveryModal
                    isModalOpen={isStatusRecoveryModalOpen}
                    setIsModalOpen={setIsStatusRecoveryModalOpen}
                    setRecoveryCodeModalOpen={setIsRecoveryCodeOpen}
                    setLinkEmailModalOpen={setIsLinkEmailModalOpen}
                />
            ) : null}

            {isStatusConsentModalOpen ? (
                <StatusConsentModal
                    isModalOpen={isStatusConsentModalOpen}
                    setIsModalOpen={setIsStatusConsentModalOpen}
                />
            ) : null}

            {isConnectDiscordOpen ? (
                <ConnectDiscord
                    isModalOpen={isConnectDiscordOpen}
                    setIsModalOpen={setIsConnectDiscordOpen}
                    setConnectDiscord={setConnectDiscord}
                />
            ) : null}
        </div>
    );
}
