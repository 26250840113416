// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

import { useContext, useState, useEffect } from "react";
import Context from "../../store/context";

import Login from "./Login";
import SignUp from "./SignUp/";
import NKAPI from "../../components/NKAPI";
import SeenBefore from "./SeenBefore";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Onboarding() {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    function changeOnboardingPage(desiredPage) {
        if (globalState.onboarding === desiredPage) return;

        globalDispatch({
            type: "onboarding/update",
            payload: {
                onboarding: desiredPage // false = login - true = sign up
            }
        });
    }

    // On mount
    const [isUserSeen, setIsUserSeen] = useState(false);
    const [seenUser, setSeenUser] = useState(false);
    // TODO: Only do this once per sessions - might have to use globalState to keep a log
    useEffect(() => {
        // Check if user clicked "New User" button, if so send to sign up screen
        if (NKAPI.getQueryVariable("signup")) {
            changeOnboardingPage(true);
        }

        // Reenable exit buttons
        globalDispatch({
            type: "ui/navigation",
            payload: {
                isShown: true
            }
        });

        if (NKAPI.sessionID) {
            // Check if client has provided a session, if so log user in
            NKAPI.request("/user/current", {})
                .then((d) => {
                    // Valid session, log user in
                    globalDispatch({
                        type: "user/login",
                        payload: {
                            user: JSON.parse(d.data.data).user,
                            session: JSON.parse(d.data.data).session
                        }
                    });

                    setIsLoading(false);

                    // Go to profile page
                    if (!location.pathname.startsWith("/contact"))
                        navigate("/profile" + location.hash);
                })
                .catch((e) => {
                    if (e?.toJSON()?.message === "Network Error") {
                        // No internet connection, show lost connection screen
                        globalDispatch({
                            type: "modal/offline",
                            payload: {
                                isShown: true
                            }
                        });
                    }
                    // No valid session found, do normal flow (e.g. seen before)
                    checkSeenBefore();
                });
        } else {
            checkSeenBefore();
        }

        // Check if LiNK Codes are enabled
        NKAPI.request("/user/recovery/code", {
            platform: NKAPI.platform ? NKAPI.platform : "ios"
        })
            .then((d) => {
                let linkCodesEnabled = JSON.parse(d.data.data).enabled;
                globalDispatch({
                    type: "ui/linkCodesEnabled",
                    payload: {
                        enabled: linkCodesEnabled
                    }
                });
            })
            .catch(() => {
                globalDispatch({
                    type: "ui/linkCodesEnabled",
                    payload: {
                        enabled: true
                    }
                });
            });

        // eslint-disable-next-line
    }, []);

    function checkSeenBefore() {
        // Check if user has already done these checks, if so skip
        if (globalState.skipOnboardingChecks) {
            setIsUserSeen(false);
            setIsLoading(false);
            return;
        }

        // Check if user has been seen before
        NKAPI.request("/user/recovery/seenbefore", {})
            .then((data) => {
                setIsLoading(false);

                let json = JSON.parse(data?.data?.data);

                let seenUser = json?.user;
                seenUser.email = json.email;
                let found = json?.found;

                // console.log(seenUser);

                if (found) {
                    // Translate providers
                    seenUser.providersAvailable = NKAPI.translateProviders(
                        seenUser.providersAvailable
                    );

                    // Display seen before screen
                    setIsUserSeen(true);
                    setSeenUser(seenUser);
                }
            })
            .catch(() => {
                // Search for user using client inject provider IDs
                NKAPI.request("/user/search", {
                    method: "provider",
                    keys: NKAPI.loggedinProviderIDs,
                    includeOnlineStatus: true
                })
                    .then((d) => {
                        setIsLoading(false);
                        let users = JSON.parse(d.data.data).users;

                        var usersFound = Object.keys(users);
                        if (usersFound.length === 1) {
                            // var provider = usersFound[0].split("_")[0];
                            let seenUser = users[usersFound[0]];

                            seenUser.providersAvailable =
                                NKAPI.translateProviders(
                                    seenUser.providersAvailable
                                );

                            // Show provider
                            setSeenUser(seenUser);
                            setIsUserSeen(true);
                        } else {
                            setIsUserSeen(false);
                        }
                    })
                    .catch((e) => {
                        // console.log(e.response.data);
                        setIsUserSeen(false);
                        setIsLoading(false);
                    });
            })
            .finally(() => {
                // Skip any future onboarding checks for this session
                globalDispatch({
                    type: "onboarding/skipChecks",
                    payload: {
                        skipChecks: true
                    }
                });
            });
    }

    return (
        <div className="h-full">
            {isLoading ? (
                <div className="w-full h-full flex-center">
                    <FontAwesomeIcon
                        className="fa-spin text-nk text-4xl"
                        icon={faSpinnerThird}
                    />
                </div>
            ) : isUserSeen ? (
                <SeenBefore
                    user={seenUser}
                    isUserSeen={isUserSeen}
                    setIsUserSeen={setIsUserSeen}
                />
            ) : (
                <div className="h-full w-full px-1">
                    {/* Login / Sign Up Buttons */}
                    {globalState.isNavShown && (
                        <div className="flex justify-between mb-2 lg:mb-6">
                            <div
                                onClick={() => {
                                    changeOnboardingPage(false);
                                }}
                                className="flex items-center cursor-pointer group"
                            >
                                <h1
                                    className={`${
                                        !globalState.onboarding
                                            ? "text-white"
                                            : "text-gray-500 group-hover:text-gray-400"
                                    } text-xl lg:text-3xl font-semibold`}
                                >
                                    {t("onboarding.login")}
                                </h1>
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    className={`${
                                        !globalState.onboarding
                                            ? "text-nk"
                                            : "text-gray-500"
                                    } ml-3 text-xs lg:text-base group-hover:text-nk`}
                                />
                            </div>
                            <div
                                className="flex items-center cursor-pointer group"
                                onClick={() => {
                                    changeOnboardingPage(true);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    className={`${
                                        globalState.onboarding
                                            ? "text-nk"
                                            : "text-gray-500"
                                    } mr-3 text-xs lg:text-base group-hover:text-nk`}
                                />
                                <h1
                                    className={`${
                                        globalState.onboarding
                                            ? "text-white"
                                            : "text-gray-500 group-hover:text-gray-400"
                                    } text-xl lg:text-3xl font-semibold`}
                                >
                                    {t("onboarding.signup")}
                                </h1>
                            </div>
                        </div>
                    )}

                    {/* Onboarding Pages */}
                    {globalState.onboarding ? <SignUp /> : <Login />}
                </div>
            )}
        </div>
    );
}
