import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Context from "../../store/context";
import Button from "../Elements/Button";
import NKAPI from "../NKAPI";
import KiwiBird from "../../images/NK_KiwiBird_White.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
// import { faPaste } from "@fortawesome/pro-light-svg-icons";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

export default function LiNKCodeModal(props) {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);

    const { t } = useTranslation(); // locale

    const navigate = useNavigate();
    const location = useLocation();

    const [isLiNKCodeModalIsOpen, setIsLiNKCodeModalOpen] = useState(false);
    const [isLiNKCodeLoading, setIsLiNKCodeLoading] = useState(false);
    const [linkCodeAuthError, setLinkCodeAuthError] = useState(false);

    function openLiNKCode() {
        setIsLiNKCodeModalOpen(true);
        setLinkCodeAuthError(false);
    }
    function closeLiNKCode() {
        setIsLiNKCodeModalOpen(false);
    }
    function linkCodeSubmit(e) {
        e?.preventDefault();

        let code = document
            .getElementById("linkCode_Input")
            .value.trim()
            .toUpperCase();
        // Strip symbols
        let codeStripped = code.replace(":", "").replace("-", "");

        setIsLiNKCodeLoading(true);
        setLinkCodeAuthError(false);

        // Do requests
        if (code.length === 14) {
            // Do recovery code
            NKAPI.request("/user/login/recovery", { token: code })
                .then((data) => {
                    setIsLiNKCodeLoading(false);

                    let userData = JSON.parse(data.data.data);

                    // Update user state
                    globalDispatch({
                        type: "user/login",
                        payload: {
                            user: userData.user,
                            session: userData.session
                        }
                    });

                    if (NKAPI.debug || NKAPI.gotoProfile()) {
                        navigate("/profile" + location.hash);
                        NKAPI.sendToSDK("//onlylogin", data.data);
                    } else {
                        NKAPI.sendToSDK("//onlylogin", data.data);
                    }
                })
                .catch((error) => {
                    setLinkCodeAuthError(true);
                    setIsLiNKCodeLoading(false);

                    if (error?.toJSON()?.message === "Network Error") {
                        // No internet connection, show lost connection screen
                        globalDispatch({
                            type: "modal/offline",
                            payload: {
                                isShown: true
                            }
                        });
                    }
                });
        } else if (codeStripped.length === 9) {
            // Do LiNK code
            NKAPI.request("/user/login/token", { token: codeStripped })
                .then((data) => {
                    setIsLiNKCodeLoading(false);

                    let userData = JSON.parse(data.data.data);

                    // Update user state
                    globalDispatch({
                        type: "user/login",
                        payload: {
                            user: userData.user,
                            session: userData.session
                        }
                    });

                    if (NKAPI.debug || NKAPI.gotoProfile()) {
                        navigate("/profile" + location.hash);
                        NKAPI.sendToSDK("//onlylogin", data.data);
                    } else {
                        NKAPI.sendToSDK("//onlylogin", data.data);
                    }
                })
                .catch((error) => {
                    setLinkCodeAuthError(true);
                    setIsLiNKCodeLoading(false);

                    if (error?.toJSON()?.message === "Network Error") {
                        // No internet connection, show lost connection screen
                        globalDispatch({
                            type: "modal/offline",
                            payload: {
                                isShown: true
                            }
                        });
                    }
                });
        } else {
            // Invalid code
            setLinkCodeAuthError(true);
            setIsLiNKCodeLoading(false);
        }
    }

    return (
        <>
            {globalState.linkCodesEnabled && (
                <div>
                    {/* LiNK Code Button */}
                    <div className="flex flex-col">
                        <Button
                            text={t("onboarding.linkcode_login")}
                            className="bg-nk focus:ring-nk"
                            image={KiwiBird}
                            onClick={() => {
                                openLiNKCode(this);
                            }}
                        />
                    </div>

                    {/* LiNK Code Modal */}
                    <Modal
                        isOpen={isLiNKCodeModalIsOpen}
                        onRequestClose={closeLiNKCode}
                        contentLabel="LiNK Code"
                        className="2xl:w-6/12 md:w-9/12 sm:w-10/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
                        overlayClassName="fixed h-screen w-full top-0 flex-center"
                    >
                        <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                            {/* Modal Header */}
                            <div className="h-20 flex justify-between items-center px-1">
                                {/* No translation as LiNK Code is a brand name */}
                                <h2 className="text-xl lg:text-3xl font-semibold">
                                    LiNK Code
                                </h2>
                                <FontAwesomeIcon
                                    className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                                    icon={faTimes}
                                    onClick={closeLiNKCode}
                                />
                            </div>

                            {/* Modal Content */}
                            <form
                                className="flex-1 overflow-y-auto p-1"
                                onSubmit={linkCodeSubmit}
                            >
                                <h3 className="font-medium text-lg">
                                    {t("onboarding.linkcode_intro")}
                                </h3>
                                <div className="flex my-5 items-center">
                                    <div className="flex w-62 max-w-full relative items-center">
                                        <input
                                            className="tracking-widest uppercase rounded-full bg-gray-600 w-92 max-w-full h-12 text-center lg:h-14 text-base lg:text-2xl px-8 focus:ring-2 focus:ring-nk focus:ring-offset-gray-800 focus:ring-offset-2 outline-none placeholder-gray-300"
                                            type="text"
                                            placeholder="XXXXX-XXXX"
                                            id="linkCode_Input"
                                            maxLength="15"
                                        />
                                    </div>
                                </div>
                                <p className="mb-2 text-base text-gray-00">
                                    {t("onboarding.linkcode_help")}
                                </p>
                                <div className="flex flex-wrap items-center">
                                    <Button
                                        id="linkCode_Submit"
                                        text={
                                            isLiNKCodeLoading ? (
                                                <FontAwesomeIcon
                                                    className="fa-spin text-2xl"
                                                    icon={faSpinnerThird}
                                                />
                                            ) : (
                                                t("onboarding.login")
                                            )
                                        }
                                        className="mr-4"
                                        width="w-40"
                                        type="submit"
                                    />
                                    {linkCodeAuthError && (
                                        <div className="bg-nk h-7 rounded-full text-white font-semibold text-sm px-4 py-1 my-2">
                                            {t("error.linkcode_error")}
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </Modal>
                </div>
            )}
        </>
    );
}
